
import OfferCard from '@/components/offer_card'
import SupplierSelect from '@/components/supplier_select'
import PageNavbar from '@/components/page_navbar'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  components: {
    OfferCard,
    SupplierSelect,
    PageNavbar,
    LoadingSpinner
  }

  mounted: ->
    @querystring = @$router.currentRoute.query.search || ''
    @on_search() if @querystring.length > 0
    @$refs['search-input'].focus()

  computed: {
    loading: -> @$store.state.offers.loading
    results: -> @$store.state.offers.all
  }

  data: -> {
    error: false
    querystring: ''
    queried: ''
  }

  destroyed: ->
    @$store.commit('offers/reset')

  methods: {
    on_search: (event) ->
      @error = false
      @queried = @querystring
      @$router.push({ query: { search: @querystring } }).catch (error) ->
        return if String(error).includes('Avoided redundant navigation to current location')
        console.error 'search push catch:', error
      @$store.dispatch('analytics/search', @querystring)
      @$store.dispatch('offers/search', @querystring)
      .catch (error) ->
        @error = true
        console.error 'search catch:', error

  }

}
